<template>
  <div class="inter-wrapper">
    <HeaderInter />
    <div class="inter-content">
      <div class="alert-area container-fluid">
        <b-icon icon="check2" class="icon-success" />
        <h3>付款成功</h3>
        <div class="btn-area">
          <button class="btn btn-next" type="button" @click="toItemDetail()">
            查看訂單
          </button>
          <router-link class="btn btn-next line" to="/">
            回商城
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderInter from '@/components/HeaderInter'
import { updatePoint } from '@/utils/user'
import { showNotice } from '@/utils/tool'
import { ordFinpromotions } from '@/utils/promotions'
// import { getOrder } from '@/api/payment'

export default {
  name: 'OrderFinish',
  components: {
    HeaderInter
  },
  data() {
    return {
      order_num: null,
      orderInfo: null,
      backPath: '/product',
      errorMsg: null
    }
  },
  created() {
    const orderItem = this.$store.getters.orderItem
    if (orderItem) {
      this.backPath = '/product/detail/' + orderItem.item_id
    }
    // 完成訂單後 更新點數
    if (
      this.$store.getters.memberInfo &&
      this.$store.getters.memberInfo.point
    ) {
      updatePoint()
    }

    // 活動完成判斷
    ordFinpromotions(this.$route.query.order_num)

    // 清除storage
    this.$store.commit('SET_DEFAULT_INFO')
    this.$store.commit('SET_DEFAULT_ITEM')
    this.$store.commit('SET_PAYMENT_ORDER_ID', null)

    const host = new URL(window.location.href || document.location.href)
    this.order_num = host.searchParams.get('order_num')
    // 無訂單編號會到失敗畫面
    if (this.order_num === null) {
      this.errorMsg = '訂單異常, 若有疑問請洽客服人員'
      showNotice(this.errorMsg, function() {})
      this.$router.push('/order/fail')
    }
    // else {
    //   getOrder(this.order_num).then(response => {
    //     if (response.data.data) {
    //       this.orderInfo = response.data.data
    //     } else {
    //       this.errorMsg = '訂單異常, 若有疑問請洽客服人員'
    //     }
    //   })
    // }
  },
  mounted() {},
  methods: {
    toItemDetail() {
      if (this.order_num) {
        this.$router.push('/order/detail/' + this.order_num)
      } else {
        this.$router.push('/order')
      }
    }
  }
}
</script>
